<template>
    <div id="page-loader">
        <div id="background-cover" class="fixed top-0 left-0 z-50 bg-white w-screen h-screen"></div>
        <div id="background-loader" class="fixed top-0 left-0 z-50 bg-green-900 w-screen" :style="{ height: backgroundHeight + 'vh' }"></div>
        <div class="fixed top-0 left-0 z-50 w-screen h-screen text-white flex flex-col justify-center items-center">
            <span class="logo-loader w-64">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 878.6 202.3" xml:space="preserve">
                    <g class="letter" id="logo-z">
                        <path id="Shape" class="st0" d="M0 202.3v-2.6L91.3 47.4C96.1 35.7 113 3.9 91 .6h49.9l-120 201.7H0zm121.1-.9H110c-7 0-15.2-.1-24.7 0 56-3.7 60.8-34.4 66.6-48.3L141 201.5c.2 0-6.4-.1-19.9-.1zM12.8 0s6.6.1 20.1.1h11c7 0 15.3.1 24.7 0C12.6 3.8 7.8 34.5 2 48.4L12.8 0z" />
                    </g>
                    <g class="letter" id="logo-i">
                        <path id="Shape_1_" class="st0" d="M254.5 201.8c13.9-5.7 22.6-13.2 26.3-69.8 3.4 56.3 12.5 64.1 26.3 69.8h-52.6zM255.3.3h52.6c-13.9 5.8-22.8 10.3-26.3 67-3.7-56-12.5-61.2-26.3-67z" />
                    </g>
                    <g class="letter" id="logo-n">
                        <path id="Shape_2_" class="st0" d="M430.5 201.8c13.9-5.7 22.6-13.2 26.3-69.8 3.4 56.3 12.5 64.1 26.3 69.8h-52.6zm176.1-.8L453.8 15.8C445.6 6 437.7 2.9 423.5 0H466l143.2 174.1V201h-2.6zM569.3.3h52.6c-13.9 5.8-22.8 10.3-26.3 67-3.7-56-12.5-61.2-26.3-67z" />
                    </g>
                    <g class="letter" id="logo-c">
                        <path id="Shape_3_" class="st0" d="M740.9 165.1a102.1 102.1 0 0 1-20.4-62.1c0-22.3 7.1-44 20.1-62 13-17.9 31.3-31.2 52.4-38-29.7 15.5-49.8 55.8-49.8 98.9 0 43.8 20.7 84.8 51.2 99.7-21.4-5.9-40.2-18.8-53.5-36.5zM878.6 151v39.4c-12.9 4.9-24.5 9.5-38.1 10.6 15.7-7.5 29.1-27.6 38.1-50zM840.5.7c13.6 1.1 25.2 5.7 38.1 10.6v39.3c-9-22.3-22.4-42.4-38.1-49.9z" />
                    </g>
                </svg>
            </span>
        </div>
    </div>
    <router-view v-slot="{ Component }">
        <transition name="page-fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';

const backgroundHeight = ref(0);

onMounted(() => {
    const loaderAnimation = gsap.timeline();
    loaderAnimation.to(backgroundHeight, { value: 100, duration: 0.4, ease: 'power2.inOut' });
    loaderAnimation.to({}, {
        duration: 1,
        onComplete: function() {
            const bgLoader = document.getElementById("background-loader");
            if (bgLoader) {
                bgLoader.classList.replace('top-0', 'bottom-0');
            }
            const bgCover = document.getElementById("background-cover");
            if (bgCover && bgCover.parentNode) {
                bgCover.parentNode.removeChild(bgCover);
            }
        }
    });

    // Select all letter elements
    const letters = document.querySelectorAll('.logo-loader .letter');
    const letterDelay = 0.2;
    // Loop through letters and animate them
    letters.forEach((letter, index) => {
        gsap.fromTo(
            letter, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'power2.inOut', delay: index * letterDelay }
        );
        // Pause for 500ms
        gsap.to({}, { duration: 0.5, ease: 'power2.inOut', delay: 0.5 + index * letterDelay });
        // After pausing, fade out with a delay
        gsap.to(
            letter, { opacity: 0, duration: 1, ease: 'power2.inOut', delay: 0.5 + index * letterDelay }
        );
    });

    loaderAnimation.to(backgroundHeight, { value: 0, duration: 0.4, ease: 'power2.inOut' });
    loaderAnimation.to("#page-loader", { opacity: 0, duration: 0.3, ease: 'power2.inOut', onComplete: removeLoader });
});

function removeLoader() {
    const loader = document.getElementById("page-loader");
    if (loader && loader.parentNode) {
        loader.parentNode.removeChild(loader);
    }
}
</script>
<style scoped>
.logo-loader .letter {
    fill: white;
}

#background-loader {
    background-color: #091510;
}

.page-container {
    opacity: 1;
    transition-property: opacity;
    transition-duration: .25s;
}

.page-fade-enter,
.page-fade-leave-active,
.page-fade-enter-active,
.page-fade-enter-to {
    opacity: 0;
}
</style>