<template>
  <div class="counting-number text-center" ref="countingNumber">
    <div class="font2-bold w-full text-6xl">
      <slot name="before"></slot>
      <span ref="counter">{{ currentNumber }}</span>
      <slot name="after"></slot>
    </div>
    <div class="font1 uppercase">{{ label }}</div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
  props: {
    from: Number, // Starting number
    to: Number, // Target number
    duration: Number, // Animation duration in seconds
    label: String, // Label for the number
  },
  data() {
    return {
      currentNumber: this.from,
      scrollTrigger: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.setupScrollTrigger();
  },
  methods: {
    setupScrollTrigger() {
      const roundedValue = Math.round(this.to); // Round the 'to' value
      const counter = this.$refs.counter;

      this.scrollTrigger = ScrollTrigger.create({
        trigger: this.$refs.countingNumber,
        onEnter: () => {
          gsap.to(counter, {
            innerHTML: roundedValue, // Use the rounded value
            duration: this.duration,
            ease: 'power2.out',
            onUpdate: () => {
              counter.innerHTML = Math.round(counter.innerHTML); // Ensure the displayed value is rounded during animation
            },
          });
        },
        onLeaveBack: () => {
          gsap.to(counter, {
            innerHTML: this.from, // Animate back to the initial value
            duration: this.duration,
            ease: 'power2.out',
            onUpdate: () => {
              counter.innerHTML = Math.round(counter.innerHTML); // Ensure the displayed value is rounded during animation
            },
          });
        },
      });
    },
  },
  beforeUnmount() {
    if (this.scrollTrigger) {
      this.scrollTrigger.kill(); // Kill the ScrollTrigger instance to avoid memory leaks
    }
  },
};
</script>

<style scoped>
.counting-number {
  text-align: center;
  /* Add your styles here */
}
</style>
