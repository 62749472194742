//import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import NotFound from '../views/NotFound.vue';
import PageLoader from '../includes/PageLoader.vue';
import HomePage from '../views/HomePage.vue';
import TeamPage from '../views/TeamPage.vue';
import StoryPage from '../views/StoryPage.vue';
import ProjectsPage from '../views/ProjectsPage.vue';
import ProjectItemPage from '../views/ProjectItemPage.vue';
import ContactPage from '../views/ContactPage.vue';

//createWebHashHistory

const routes: Array < RouteRecordRaw > = [{
    path: '/',
    component: PageLoader, // Use PageTransition as the layout
    children: [{
            path: '',
            component: HomePage,
            name: 'home',
            meta: {
                title: 'A scaling multidisciplinary real estate company - ZINC Real Estate',
            },
        },
        {
            path: 'team',
            component: TeamPage,
            name: 'team',
            meta: {
                title: 'Our Team - ZINC Real Estate',
            },
        },
        {
            path: 'story',
            component: StoryPage,
            name: 'story',
            meta: {
                title: 'About ZINC Real Estate',
            },
        },
        {
            path: 'projects/:projectCategory?',
            component: ProjectsPage,
            name: 'projects',
            meta: {
                title: 'Projects of ZINC Real Estate',
            }
        },
        {
            path: 'project/:projectId?',
            component: ProjectItemPage,
            name: 'project',
            meta: {
                title: 'Project of ZINC Real Estate',
            },
        },
        {
            path: 'contact',
            component: ContactPage,
            name: 'contact',
            meta: {
                title: 'Contact ZINC Real Estate',
            },
        },
        {
            path: '/:catchAll(.*)', // Matches any undefined path
            component: NotFound, // Render the NotFound component
            name: 'not-found', // Give it a name for easy navigation
            meta: {
                title: '404 - Not Found', // Set the page title for the 404 page
            },
        },
        {
            path: '/notfound', // Matches any undefined path
            component: NotFound, // Render the NotFound component
            name: '404', // Give it a name for easy navigation
            meta: {
                title: '404 - Not Found', // Set the page title for the 404 page
            },
        },
    ],
}, ];

const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    linkExactActiveClass: 'main_nav_active',
});

/*    const leavingPage = String(from.name);
    //const enteringPage = String(to.name);
    // GSAP animation for page transition
    const tl = new TimelineMax();
    tl.fromTo(`.${leavingPage}-container`, 0.5, { opacity: 1 }, { opacity: 0 }).call(next);
        //.fromTo(`.${enteringPage}-container`, 0.5, { opacity: 0 }, { opacity: 1 })
});*/


const DEFAULT_TITLE = 'SPA App';

router.afterEach((to) => {
    document.title = to?.meta?.title as string || DEFAULT_TITLE;
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
});

export default router;