import { State } from 'vue';

const mutationsNames = {
  setContents: 'setContents',
  setUrl: 'setUrl',
};

const mutations = {
  [mutationsNames.setContents](state: State, contents: unknown): void {
    state.contents = contents;
  },
  [mutationsNames.setUrl](state: State, url: string): void {
    state.url = url;
  },
};

export { mutationsNames, mutations };
