
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    components: {},
    mounted() {
        document.body.classList.add('dark-header');

        // Get all elements with the class "ani-top"
        const elements = document.querySelectorAll('.ani-top');

        gsap.set(elements, { opacity: 0, y: -50 });

        gsap.to(elements, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: 'slow',
            stagger: 0.2

        });
        
    },
    unmounted() {
        //document.body.classList.remove('dark-header');
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    }
};
