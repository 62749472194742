
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import SvgIconNext from '../includes/SvgIconNext.vue';
import ResponsiveVideo from '../includes/ResponsiveVideo.vue';
import CountingNumber from '../includes/CountingNumber.vue';

interface Post {
    id: number;
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
    slug: string;
}

export default {

    components: {
        SvgIconNext,
        ResponsiveVideo,
        CountingNumber,
    },
    setup() {
        const posts = ref < Post[] > ([]);

        onMounted(() => {
            // Replace 'your-wordpress-api-url' with your WordPress API endpoint.
            axios.get < Post[] > ('https://zincre.com/api/wp-json/wp/v2/cvr_portfolio?_embed=&portfoliocategory=5&lang=en')
                .then(response => {
                    posts.value = response.data;
                })
                .catch(error => {
                    console.error('Error fetching WordPress posts:', error);
                });
        });

        // Function to decode HTML entities
        function decodeEntities(html: string): string {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = html;
            return textArea.value;
        }

        return {
            posts,
            decodeEntities
        };
    },
    mounted() {
        const usp = document.getElementById('usp');
        const mainbgGray = document.getElementById('mainbg-grayscale');
        const mainbgOverlay = document.getElementById('mainbg-overlay');
        document.body.classList.remove('dark-header');

        if (usp && mainbgGray && mainbgOverlay) {
            mainbgGray.classList.add('opacity-0');
            mainbgOverlay.classList.add('opacity-0');
            ScrollTrigger.create({
                trigger: usp,
                start: 'top center',
                //markers: true,
                onToggle: self => {
                    if (self.isActive) {
                        mainbgGray.classList.remove('opacity-0');
                        mainbgOverlay.classList.remove('opacity-0');
                    } else {
                        mainbgGray.classList.add('opacity-0');
                        mainbgOverlay.classList.add('opacity-0');
                    }
                },
            });
            ScrollTrigger.create({
                trigger: usp,
                start: 'top 75px',
                //markers: true,
                onToggle: self => {
                    if (self.isActive) {
                        document.body.classList.add('dark-header');
                    } else {
                        document.body.classList.remove('dark-header');
                    }
                },
            });
        }
        // Get all elements with the class "ani-top"
        const elements = document.querySelectorAll('.ani-top');

        gsap.set(elements, { opacity: 0, y: -50 });

        ScrollTrigger.batch(elements, {
            start: "bottom bottom",
            end: "top top",
            onEnter: (element) => {
                gsap.to(element, {
                    y: 0,
                    opacity: 1,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2,
                });
            },
            onLeaveBack: (element) => {
                gsap.to(element, {
                    y: -50,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2
                });
            }
        });
    },
    unmounted() {
        //document.body.classList.remove('dark-header');
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    }
}
