<template>
    <a href="#" :class="mode" @mouseenter="animateCaret(true)" @mouseleave="animateCaret(false)" @click="scrollToTarget(target)" aria-label="Next">
        <svg class="nexbtn w-12" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 110 110" xml:space="preserve">
            <circle class="circle" cx="55" cy="55" r="50" :stroke="color" fill="none" stroke-width="4" />
            <polyline class="caret" points="33.7,46.8 54.2,67.4 74.7,46.9 " style="stroke-linecap:round; transform-origin: center;" fill="none" :stroke="color" stroke-width="4" />
        </svg>
    </a>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    props: {
        target: String,
        mode: String,
        color: {
            type: String,
            default: '#fff',
        },
    },
    methods: {
        animateCaret(isHovered) {
            const caret = this.$el.querySelector('.caret');
                gsap.to(
                    caret, {
                        y: isHovered ? 10 : 0,
                        opacity: 1,
                        duration: isHovered ? 0.1 : 0.3,
                        ease: "power4"
                    }
                );
        },
        scrollToTarget(target) {
            event.preventDefault(); // Prevent default anchor behavior
            const targetElement = document.querySelector(target);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },
    mounted() {
        // Define the circle element
        const circle = this.$el.querySelector('.circle');

        // Get the total length of the circle's path
        const totalLength = circle.getTotalLength();

        // Set the initial state of the stroke
        gsap.set(circle, { strokeDasharray: totalLength, strokeDashoffset: totalLength, opacity: 0 });

        // Create an animation to reveal the circle
        const circleAnimation = gsap.to(circle, {
            strokeDashoffset: 0, // Animate the strokeDashoffset to reveal the circle
            opacity: 1,
            duration: 1, // Adjust the duration as needed
            ease: "circ.out",
            paused: true, // Start paused
        });

        // ScrollTrigger to play and reverse the animation
        ScrollTrigger.create({
            trigger: circle,
            start: "bottom bottom",
            end: "top +=200px",
            // scrub: true, // Smooth animation during scroll
            markers: false,
            onEnter: () => circleAnimation.play(),
            onLeave: () => circleAnimation.reverse(),
            onEnterBack: () => circleAnimation.play(),
            onLeaveBack: () => circleAnimation.reverse(),
        });
    },
};
</script>
<style>
.nexbtn .circle {
    transform: rotate(90deg);
    transform-origin: center;
}

/* CSS for the caret when in "up" mode */
.mode-up .nexbtn {
    transform: rotate(180deg);
}

.mode-up .nexbtn .circle {
    transform: rotate(270deg);
    transform-origin: center;
}
</style>