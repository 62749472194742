<template>
    <div class="w-full min-h-[calc(100dvh)] flex flex-col p-10 bg-gradient-to-r from-[#091510] to-[#0D3123]">
        <div class="h-12">
            <!--spacer-->
        </div>
        <div class="flex-grow flex flex-col items-center justify-center sm:mb-20">
            <div class="text-center text-white">
                <h1 class="font1 text-xl mb-5 uppercase ani-top">Page Not Found</h1>
                <p class="font2 text-2xl max-w-4xl ani-top">The page you are looking for does not exist.</p>
            </div>
        </div>
        <div class="mx-auto">
            <router-link to="/" class="font1 text-white underline hover:no-underline">back to homepage</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NotFound',
};
</script>
<style scoped>
/* Add your custom CSS styles for the 404 page */
</style>