<template>
    <div>
        <video id="video-bg" ref="videoPlayer" autoplay muted loop playsinline>
            <source :src="selectedQualitySource" type="video/mp4" />
        </video>
    </div>
</template>
<script>
export default {
    data() {
        return {
            qualitySources: {
                '1080p': '../../video/zincv2-1080.mp4',
                '720p': '../../video/zincv2-720.mp4',
                '480p': '../../video/zincv2-540.mp4',
                '360p': '../../video/zincv2-360.mp4',
            },
            selectedQuality: '720p', // Default quality
        };
    },
    computed: {
        selectedQualitySource() {
            return this.qualitySources[this.selectedQuality];
        },
    },
    mounted() {
        // Delay loading the video until after the page is loaded
        this.loadVideo
    },
    methods: {
        loadVideo() {
            // Determine the video quality based on window width
            const windowWidth = window.innerWidth;

            if (windowWidth < 800) {
                this.selectedQuality = '360p';
            } else if (windowWidth < 1280) {
                this.selectedQuality = '480p';
            } else if (windowWidth < 1920) {
                this.selectedQuality = '720p';
            } else {
                this.selectedQuality = '1080p'; // Use 1080p for larger screens
            }

            // Load the video element
            this.$refs.videoPlayer.load();
            
            // Remove the load event listener after the video is loaded
            //window.removeEventListener('load', this.loadVideo);
        },
    },
    beforeUnmount() {
        // Remove the window resize event listener when the component is destroyed
        //window.removeEventListener('resize', this.updateVideoQuality);
    },
};
</script>
<style scoped>
/* Style for the video background */
#video-bg {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    /* Ensure it's behind your other content */
    object-fit: cover;
    /* Cover the entire viewport */
}

/* Your existing styles can go here */
</style>