// Style imports
import '../css/main.scss';

// App
import { createApp } from 'vue';
import router from './router';
import store from './stores';
import App from './App.vue';
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(router);
app.use(store);

app.use(VueGtag, {
  config: { 
    id: "G-WW85Y6V3GP",
    params: {
      anonymize_ip: true
    }
  }
}).mount('#app');
