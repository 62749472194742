
import { ref } from 'vue';
import axios from 'axios';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRoute } from 'vue-router';
import { pageview, optOut, optIn } from 'vue-gtag';

gsap.registerPlugin(ScrollTrigger);

import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

interface Projectitems {
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
    _embedded: {
        'wp:featuredmedia': {
            source_url: string;
        } [];
    };
    slug: string;
}

export default {
    data() {
        return {
            uniqueClassNames: ['class-1', 'class-2', 'class-3', 'class-4', 'class-5'],
        };
    },
    setup() {

        const route = useRoute();

        const projectitems = ref < Projectitems[] > ([]);

        let apiUrl = '?_embed&slug=' + route.params.projectId + '&lang=en';

        fetchData(apiUrl);

        function fetchData(apiUrl: string) {
            axios.get < Projectitems[] > ('https://zincre.com/api/wp-json/wp/v2/cvr_portfolio' + apiUrl)
                .then(response => {
                    projectitems.value = response.data;
                    if (projectitems.value.length > 0) {
                        const newTitle = decodeEntities(projectitems.value[0].title.rendered) + ' - ZINC Real Estate';
                        document.title = newTitle; // Update document title
                        optIn();
                        pageview({ // Send pageview event to GA
                            page_title: newTitle,
                            page_path: window.location.pathname
                            // Additional custom parameters if needed
                        });
                    }
                    animateElements();
                })
                .catch(error => {
                    console.error('Error fetching WordPress posts:', error);
                });
        }

        function animateElements() {
            setTimeout(() => {
                const swiperScrollbarCustom = document.querySelectorAll('.swiper-scrollbar-custom > div');
                Fancybox.bind('[data-fancybox="gallery"]', {});
                const swiper = new Swiper('.swiper', {
                    modules: [Navigation, Scrollbar],
                    slidesPerView: 3, // Set the number of slides visible at the same time
                    spaceBetween: 80, // Adjust the space between slides as needed
                    breakpoints: {
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        // when window width is >= 480px
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 30
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        // when window width is >= 640px
                        940: {
                            slidesPerView: 3,
                            spaceBetween: 40
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar-custom',
                    },
                    on: {
                        progress: swiper => {
                            gsap.to(swiperScrollbarCustom, { width: `${swiper.progress * 100}%` })
                        },
                    },
                });
                swiper.on('slideChange', () => {
                    // Handle slide change event
                });


                const elements = document.querySelectorAll('.ani-top');
                const elementsNow = document.querySelectorAll('.ani-top-now');
                gsap.set(elements, { opacity: 0, y: -50 });
                gsap.set(elementsNow, { opacity: 0, y: -50 });
                gsap.to('.page-container > div', {
                    opacity: 1,
                    onComplete: function() {
                        document.body.classList.remove('dark-header');
                        gsap.to('.ani-height', { height: '100%' });
                        gsap.to(elementsNow, {
                            y: 0,
                            opacity: 1,
                            duration: 0.5,
                            ease: 'slow',
                        });
                        ScrollTrigger.batch(elements, {
                            start: "bottom bottom",
                            end: "top top",
                            onEnter: (element) => {
                                gsap.to(element, {
                                    y: 0,
                                    opacity: 1,
                                    duration: 0.5,
                                    ease: 'slow',
                                    stagger: 0.2
                                });
                            }
                        });
                    }
                });
            }, 10);
        }

        // Function to decode HTML entities
        function decodeEntities(html: string): string {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = html;
            return textArea.value;
        }

        return {
            projectitems,
            decodeEntities
        };
    },
    mounted() {
        optOut();
        document.body.classList.add('dark-header');
    },
    unmounted() {
        optIn();
        //document.body.classList.remove('dark-header');
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    },
}
