<template>
    <div class="w-full">
        <div class="menucontainer fixed h-[calc(100dvh)] bg-black w-full z-50 left-0 top-0 py-36 px-8 sm:px-10 md:px-16 text-white opacity-0 overflow-scroll" :style="{ 'pointer-events': isMenuOpen ? 'auto' : 'none' }">
            <div class="fixed left-0 top-0 w-full h-full z-[-1]">
                <!--<img class="absolute top-0 left-0 object-cover w-full h-full" src="../../images/bg-zinc.jpg" />-->
                <ResponsiveVideo />
                <div class="fixed top-0 left-0 w-full h-full bg-gradient-to-r from-[#000000]/90 via-[#080F0F]/90 to-[#0A241A]/90"></div>
            </div>
            <div class="font1 w-full h-full max-w-7xl mx-auto sm:flex sm:items-center">
                <div class="sm:flex w-full">
                    <div class="sm:flex-1 sm:flex sm:items-center">
                        <ul class="mainmenu-item w-full text-left py-6 px-3 lg:px-14 text-4xl uppercase">
                            <li>
                                <a class="menu-item border-b-[1px] mb-5 py-8 pr-14 block relative opacity-60 transition hover:opacity-100" href="#" @mouseenter="animateMenuItem(true, $event, 'about')" @mouseleave="animateMenuItem(false, $event, 'about')" @click="openMenuItem('about')" data-menu="about" id="menu-item-about">
                                    <span>About</span>
                                    <svg class="menuarrow w-28 absolute right-0 bottom-5 hidden sm:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.6 12.7" xml:space="preserve">
                                        <path d="M91.9 6.4H1.3" id="line-arrow" />
                                        <path d="m87.2.7 5.7 5.7-5.7 5.6" id="arrow" />
                                    </svg>
                                </a>
                            </li>
                            <!-- about needs to be here on mobile -->
                            <li>
                                <a class="menu-item border-b-[1px] mb-5 py-8 pr-14 block relative opacity-60 transition hover:opacity-100" href="#" @mouseenter="animateMenuItem(true, $event, 'projects')" @mouseleave="animateMenuItem(false, $event, 'projects')" @click="openMenuItem('projects')" id="menu-item-projects">
                                    <span>Projects</span>
                                    <svg class="menuarrow w-28 absolute right-0 bottom-5 hidden sm:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.6 12.7" xml:space="preserve">
                                        <path d="M91.9 6.4H1.3" id="line-arrow" />
                                        <path d="m87.2.7 5.7 5.7-5.7 5.6" id="arrow" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a class="menu-item border-b-[1px] mb-5 py-8 pr-14 block relative opacity-60 transition hover:opacity-100" href="#" @mouseenter="animateMenuItem(true, $event, 'contact')" @mouseleave="animateMenuItem(false, $event, 'contact')" @click="openMenuItem('contact')" id="menu-item-contact">
                                    <span>Contact</span>
                                    <svg class="menuarrow w-28 absolute right-0 bottom-5 hidden sm:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.6 12.7" xml:space="preserve">
                                        <path d="M91.9 6.4H1.3" id="line-arrow" />
                                        <path d="m87.2.7 5.7 5.7-5.7 5.6" id="arrow" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="flex-1">
                        <div id="submenu-about" class="submenu-container flex w-full h-full md:mt-[49px] opacity-0 hidden">
                            <ul class="text-left text-lg md:text-2xl py-6 py-6 px-3 lg:px-14  w-full sm:w-auto">
                                <li>
                                    <router-link to="/team" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" href="#" @click="closeMenu">
                                        Our Team
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/story" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" @click="closeMenu">
                                        Our Story
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div id="submenu-projects" class="submenu-container flex w-full h-full items-center md:mt-[24px] opacity-0 hidden">
                            <ul class="text-left text-lg md:text-2xl py-6 py-6 px-3 lg:px-14  w-full sm:w-auto">
                                <li>
                                    <router-link to="/projects" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" href="#" @click="closeMenu">
                                        All Projects
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/projects/investments" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" href="#" @click="closeMenu">
                                        Investments
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="/projects/developments" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" href="#" @click="closeMenu">
                                        Developments
                                    </router-link>
                                </li>
                                 <li>
                                    <router-link to="/projects/loans" class="border-b-[1px] mb-5 py-2 pr-20 uppercase block opacity-60 transition hover:opacity-100 whitespace-nowrap" href="#" @click="closeMenu">
                                        Loans
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div id="submenu-contact" class="submenu-container flex w-full h-full items-center opacity-0 hidden">
                            <ul class="text-left text-lg md:text-2xl py-6 py-6 px-3 lg:px-14 w-full sm:w-auto">
                                <li class="border-b-[1px] mb-5 py-5 uppercase block"><a class="block opacity-60 transition hover:opacity-100 mb-5" href="https://maps.app.goo.gl/DrpYhR6QrWmW7qTb8" target="_blank">Handelsweg 59<br>1181 ZA, Amstelveen</a>
                                    <a class="block opacity-60 transition hover:opacity-100" href="https://maps.app.goo.gl/NgHZ4iheRdhKBZFz9" target="_blank">68 Cornhill<br>London EC3V 3QX</a></li>
                                <li class="border-b-[1px]  py-5 uppercase block">
                                    <a class="block opacity-60 transition hover:opacity-100" href="mailto:info@zincre.com">info@zincre.com</a>
                                    <a class="block opacity-60 transition hover:opacity-100" href="tel:+310627548327">+31 06 27 54 83 27</a>
                                </li>
                                <li class="border-b-[1px] mb-5 py-5 uppercase block">
                                    <a class="uppercase opacity-60 transition hover:opacity-100" href="https://www.linkedin.com/company/zinc-real-estate/" target="_blank">LinkedIn</a>&nbsp;
                                    <router-link to="/contact" class="uppercase opacity-60 transition hover:opacity-100" @click="closeMenu">Contact us</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header class="text-center absolute lg:fixed w-screen z-50 p-10 flex justify-between content-start">
            <div class="logo-header mt-1 w-44">
                <router-link to="/" @click="closeMenu(true)" aria-label="Zinc RE">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 878.6 202.3" xml:space="preserve">
                        <g class="letter">
                            <path id="Shape" class="st0" d="M0 202.3v-2.6L91.3 47.4C96.1 35.7 113 3.9 91 .6h49.9l-120 201.7H0zm121.1-.9H110c-7 0-15.2-.1-24.7 0 56-3.7 60.8-34.4 66.6-48.3L141 201.5c.2 0-6.4-.1-19.9-.1zM12.8 0s6.6.1 20.1.1h11c7 0 15.3.1 24.7 0C12.6 3.8 7.8 34.5 2 48.4L12.8 0z" />
                        </g>
                        <g class="letter">
                            <path id="Shape_1_" class="st0" d="M254.5 201.8c13.9-5.7 22.6-13.2 26.3-69.8 3.4 56.3 12.5 64.1 26.3 69.8h-52.6zM255.3.3h52.6c-13.9 5.8-22.8 10.3-26.3 67-3.7-56-12.5-61.2-26.3-67z" />
                        </g>
                        <g class="letter">
                            <path id="Shape_2_" class="st0" d="M430.5 201.8c13.9-5.7 22.6-13.2 26.3-69.8 3.4 56.3 12.5 64.1 26.3 69.8h-52.6zm176.1-.8L453.8 15.8C445.6 6 437.7 2.9 423.5 0H466l143.2 174.1V201h-2.6zM569.3.3h52.6c-13.9 5.8-22.8 10.3-26.3 67-3.7-56-12.5-61.2-26.3-67z" />
                        </g>
                        <g class="letter">
                            <path id="Shape_3_" class="st0" d="M740.9 165.1a102.1 102.1 0 0 1-20.4-62.1c0-22.3 7.1-44 20.1-62 13-17.9 31.3-31.2 52.4-38-29.7 15.5-49.8 55.8-49.8 98.9 0 43.8 20.7 84.8 51.2 99.7-21.4-5.9-40.2-18.8-53.5-36.5zM878.6 151v39.4c-12.9 4.9-24.5 9.5-38.1 10.6 15.7-7.5 29.1-27.6 38.1-50zM840.5.7c13.6 1.1 25.2 5.7 38.1 10.6v39.3c-9-22.3-22.4-42.4-38.1-49.9z" />
                        </g>
                    </svg>
                </router-link>
            </div>
            <nav>
                <a href="#" class="relative" :class="mode" @mouseenter="animateMenu(true)" @mouseleave="animateMenu(false)" @click="openMenu()" aria-label="Menu">
                    <!--span class="menu-text text1 uppercase absolute right-10 top-[24px] text-white">menu</span>
                    <span class="menu-text-close text1 uppercase absolute right-10 top-[24px] text-white">close</span-->
                    <svg class="menubtn w-12" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 110 110" xml:space="preserve">
                        <circle class="circle" cx="55" cy="55" r="50" :stroke="color" fill="none" stroke-width="4" />
                        <g>
                            <line class="line-top" x1="26.5" y1="47.2" x2="83.5" y2="47.2" :stroke="color" fill="none" stroke-width="4" />
                            <line class="line-bottom" x1="26.5" y1="62.8" x2="83.5" y2="62.8" :stroke="color" fill="none" stroke-width="4" />
                        </g>
                    </svg>
                </a>
            </nav>
        </header>
    </div>
</template>
<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import ResponsiveVideo from '../includes/ResponsiveVideo.vue';

export default {
    data() {
        return {
            isMenuOpen: false, //tijdelijk op true om te testen
            activeMenuItem: null,
            isMenuFinished: true
        };
    },
    components: {
        ResponsiveVideo,
    },
    props: {
        target: String,
        mode: String,
        color: {
            type: String,
            default: '#fff',
        },
    },
    methods: {
        animateMenu(isHovered) {
            if (window.innerWidth > 768) {
                const linetop = this.$el.querySelector('.line-top');
                const linebottom = this.$el.querySelector('.line-bottom');
                gsap.set(linetop, { transformOrigin: "50% 50%" });
                gsap.set(linebottom, { transformOrigin: "50% 50%" });
                if (!this.isMenuOpen) {
                        gsap.to(linetop, {
                            y: isHovered ? 8 : 0,
                            rotation: 0,
                            duration: isHovered ? 0.2 : 0.4,
                            ease: isHovered ? "power4" : "back.out(5)"
                        });
                        gsap.to(linebottom, {
                            y: isHovered ? -8 : 0,
                            rotation: 0,
                            duration: isHovered ? 0.2 : 0.4,
                            ease: isHovered ? "power4" : "back.out(5)"
                        });
                } else {
                        gsap.to(linetop, {
                            rotation: isHovered ? 35 : 45,
                            y: 8,
                            duration: 0.5,
                            ease: "back.out(5)",
                        })
                        gsap.to(linebottom, {
                            rotation: isHovered ? -35 : - 45,
                            y: -8,
                            duration: 0.5,
                            ease: "back.out(5)",
                        })
                }
            }
        },
        closeMenu(clear) {
            if (this.isMenuOpen) {
                this.openMenu(true);
                if (clear) {
                    const activeSubMenu = document.querySelector('.submenu-container.active');
                    const activeMainMenu = document.querySelector('.menu-item.active');
                    if (activeSubMenu) {
                        gsap.to(activeSubMenu, {
                            opacity: 0,
                            onComplete: function() {
                                activeSubMenu.classList.remove('active');
                                activeSubMenu.classList.add('hidden');
                            }
                        })
                    }
                    if (activeMainMenu) {
                        activeMainMenu.classList.remove('active');
                        gsap.to(activeMainMenu.querySelector('.menuarrow'), { opacity: 0, y: 10, });
                    }
                }
            }
        },
        openMenu(delayed) {
            event.preventDefault(); // Prevent default anchor behavior
            if (!this.isMenuFinished) {
                return false;
            }
            var delayTime = 0;
            if (delayed) {
                delayTime = 400;
            }
            this.isMenuFinished = false;
            const linetop = this.$el.querySelector('.line-top');
            const linebottom = this.$el.querySelector('.line-bottom');
            const menucontainer = this.$el.querySelector('.menucontainer');

            gsap.set(linetop, { transformOrigin: "50% 50%" });
            gsap.set(linebottom, { transformOrigin: "50% 50%" });

            //const activeSubMenu = document.querySelector('.submenu-container.active');

            if (this.isMenuOpen) {
                //console.log('trigger close');
                setTimeout(() => {
                    //console.log('closing');
                    document.body.classList.remove('no-scroll');

                    gsap.to([linetop, linebottom], {
                        y: 0,
                        rotation: 0,
                        duration: 0.2,
                        ease: "power4",
                    });

                    gsap.to(menucontainer, {
                        opacity: 0,
                        duration: 0.5,
                        scale: 1.2,
                        onComplete: () => {
                            //console.log('closed');
                            gsap.to(menucontainer, { scale: 1 });
                            //if (activeSubMenu) {
                                //gsap.set(activeSubMenu, { opacity: 0 });
                            //}
                            this.isMenuFinished = true; // Animation completed, set to true
                        }
                    });
                }, delayTime);
            } else {
                document.body.classList.add('no-scroll');
                gsap.to(linetop, {
                    y: 8,
                    rotation: 45,
                    duration: 0.5,
                    ease: "power4",
                })
                gsap.to(linebottom, {
                    y: -8,
                    rotation: -45,
                    duration: 0.5,
                    ease: "power4",
                })

                const menuItems = this.$el.querySelectorAll('.mainmenu-item li');
                gsap.set(menuItems, { opacity: 0, y: -25, });

                gsap.to(menucontainer, {
                    opacity: 1,
                    duration: 0.5,
                    onComplete: () => {
                        menuItems.forEach((item, index) => {
                            const delay = 0.2 * index;
                            gsap.to(item, {
                                opacity: 1,
                                y: 0,
                                duration: 0.3,
                                ease: 'slow',
                                delay,
                                onComplete: () => {
                                    //if (activeSubMenu) {
                                     //   gsap.to(activeSubMenu, { opacity: 1 });
                                    //}
                                    this.isMenuFinished = true; // Animation completed, set to true
                                }
                            });
                            //TODO
                            // twijfel over deze animatie...

                            // Animate the text within the menu item
                            const textSpan = item.querySelector('span');
                            gsap.fromTo(
                                textSpan, {
                                    opacity: 0,
                                    'margin-left': '-50px',
                                }, {
                                    opacity: 1,
                                    'margin-left': '0',
                                    duration: 0.5,
                                    ease: 'slow',
                                    delay
                                }
                            );
                        });
                    }
                });
            }
            this.isMenuOpen = !this.isMenuOpen;
        },
        animateMenuItem(isHovered, event, menuItem) {
            const targetSubMenu = document.querySelector(`#submenu-${menuItem}`);
            const activeSubMenu = document.querySelector('.submenu-container.active');

            if (event.currentTarget.classList.contains('active')) {
                return false;
            } else {
                event.currentTarget.classList.add('active');
            }

            gsap.to(event.currentTarget.querySelector('.menuarrow'), {
                opacity: isHovered ? 1 : 0,
                y: isHovered ? 0 : 10,
                duration: 0.4,
                delay: isHovered ? 0 : 0.2,
                ease: 'back.out(3)',
                overwrite: "auto",
            });
            if (activeSubMenu) {
                gsap.to(activeSubMenu, {
                    opacity: 0,
                    duration: 0.2,
                    overwrite: "auto",
                    onComplete: function() {
                        activeSubMenu.classList.remove('active');
                        activeSubMenu.classList.add('hidden');
                        showMenuItem(targetSubMenu);
                    }
                })
            } else {
                showMenuItem(targetSubMenu);
            }

            function showMenuItem(target) {
                target.classList.add('active');
                target.classList.remove('hidden');
                gsap.to(target, { opacity: 1, overwrite: "auto" })
            }

            const allMenuItems = document.querySelectorAll('.menu-item');
            allMenuItems.forEach(item => {
                if (item.id !== `menu-item-${menuItem}`) {
                    gsap.to(item.querySelector('.menuarrow'), { opacity: 0, y: 10, overwrite: "auto" });
                    item.classList.remove('active');
                }
            });
        },
        openMenuItem(menuItem) {
            console.log(menuItem);
            event.preventDefault();
            /*const menuItemElement = document.querySelector(`#menu-item-${menuItem}`);
            const targetSubMenu = document.querySelector(`#submenu-${menuItem}`);
            const activeSubMenu = document.querySelector('.submenu-container.active');

            if (menuItemElement.classList.contains('active')) {
                return false;
            }

            if (menuItemElement) {
                menuItemElement.classList.add('active');
            }

            if (targetSubMenu) {
                if (activeSubMenu) {
                    gsap.to(activeSubMenu, {
                        opacity: 0,
                        onComplete: function() {
                            activeSubMenu.classList.remove('active');
                            activeSubMenu.classList.add('hidden');
                            showMenuItem(targetSubMenu);
                        }
                    })
                } else {
                    showMenuItem(targetSubMenu);
                }
            }

            function showMenuItem(target) {
                target.classList.add('active');
                target.classList.remove('hidden');
                gsap.to(target, { opacity: 1, })
            }

            const allMenuItems = document.querySelectorAll('.menu-item');
            allMenuItems.forEach(item => {
                if (item.id !== `menu-item-${menuItem}`) {
                    gsap.to(item.querySelector('.menuarrow'), { opacity: 0, y: 10, });
                    item.classList.remove('active');
                }
            });*/
        },
    },
    mounted() {
        // Select all letter elements
        const letters = document.querySelectorAll('.logo-header .letter');
        const letterDelay = 0.2;
        gsap.set(
            letters, { opacity: 0 }
        );
        setTimeout(() => {
            // Loop through letters and animate them
            letters.forEach((letter, index) => {
                gsap.to(
                    letter, { opacity: 1, duration: 1, ease: 'power2.inOut', delay: index * letterDelay }
                );
                // Pause for 500ms
                gsap.to({}, { duration: 1, ease: 'power2.inOut', delay: 0.5 + index * letterDelay });
            });
        }, 1500);

        gsap.set('.menuarrow', { opacity: 0, y: 10, });

        // Define the circle element
        const circle = this.$el.querySelector('.circle');
        const linetop = this.$el.querySelector('.line-top');
        const linebottom = this.$el.querySelector('.line-bottom');

        // Get the total length of the circle's path
        const circletotalLength = circle.getTotalLength();
        const linetoptotalLength = linetop.getTotalLength();
        const linebottomtotalLength = linebottom.getTotalLength();

        // Set the initial state of the stroke
        gsap.set(circle, { strokeDasharray: circletotalLength, strokeDashoffset: circletotalLength, opacity: 0 });
        gsap.set(linetop, { strokeDasharray: linetoptotalLength, strokeDashoffset: linetoptotalLength, opacity: 0 });
        gsap.set(linebottom, { strokeDasharray: linebottomtotalLength, strokeDashoffset: linebottomtotalLength, opacity: 0 });

        setTimeout(() => {
            // Create an animation to reveal the circle
            gsap.to(circle, {
                strokeDashoffset: 0, // Animate the strokeDashoffset to reveal the circle
                opacity: 1,
                duration: 1, // Adjust the duration as needed
                ease: "circ.out",
            });

            setTimeout(() => {
                gsap.to(linetop, {
                    strokeDashoffset: 0, // Animate the strokeDashoffset to reveal the circle
                    opacity: 1,
                    duration: 0.5, // Adjust the duration as needed
                    ease: "circ.out",
                });
                setTimeout(() => {
                    gsap.to(linebottom, {
                        strokeDashoffset: 0, // Animate the strokeDashoffset to reveal the circle
                        opacity: 1,
                        duration: 0.5, // Adjust the duration as needed
                        ease: "circ.out",
                    });
                }, 500);
            }, 500);
        }, 2000);
    },
};
</script>
<style>
.menubtn .circle {
    transform: rotate(90deg);
    transform-origin: center;
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.menubtn .circle {
    -webkit-animation: rotating 2s linear 2s;
    -moz-animation: rotating 2s linear 2s;
    -ms-animation: rotating 2s linear 2s;
    -o-animation: rotating 2s linear 2s;
    animation: rotating 2s linear 2s;
}

.menuarrow path {
    fill: none;
    stroke: #fff;
    stroke-width: 1px;
    stroke-linecap: square;
    stroke-miterlimit: 10
}

.menu-item.active {
    opacity: 1;
}

.menu-item.active .menuarrow {
    opacity: 1;
}

.no-scroll {
    overflow: hidden;
}

.main_nav_active {
    opacity: 1;
}

.logo-header .letter {
    transition: fill 500ms ease;
    fill: white;
}

.dark-header:not(.no-scroll) .logo-header .letter {
    fill: black;
}

.menubtn circle,
.menubtn line {
    transition: fill 500ms ease;
}

.dark-header:not(.no-scroll) .menubtn circle,
.dark-header:not(.no-scroll) .menubtn line {
    stroke: black;
}

.dark-header .menu-text {
    color: black;
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        /*height: -webkit-fill-available;*/
    }
}
</style>