
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import SvgIconNext from '../includes/SvgIconNext.vue';

export default {
    components: {
        SvgIconNext,
    },
    mounted() {
        document.body.classList.remove('dark-header');

        const contentSection = document.getElementById('content');

        if (contentSection) {
            ScrollTrigger.create({
                trigger: contentSection,
                start: 'top 75px',
                //markers: true,
                onToggle: self => {
                    if (self.isActive) {
                        document.body.classList.add('dark-header');
                    } else {
                        document.body.classList.remove('dark-header');
                    }
                },
            });
        }


        gsap.set('.ani-top', { opacity: 0, y: -50 });

        ScrollTrigger.batch('.ani-top', {
            start: "top bottom",
            end: "top top",
            onEnter: (element) => {
                gsap.to(element, {
                    y: 0,
                    opacity: 1,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2,
                    overwrite: "auto",
                });
            },
            onLeaveBack: (element) => {
                gsap.to(element, {
                    y: -50,
                    opacity: 0,
                    duration: 0.5,
                    ease: 'slow',
                    stagger: 0.2,
                    overwrite: "auto",
                });
            }
        });

        gsap.set('.ani-drawheight-1, .ani-drawheight-2', { height: '0' });

        ScrollTrigger.create({
            trigger: '.ani-drawheight-1, .ani-drawheight-2',
            start: 'bottom bottom',
            end: 'top top',
            scrub: true, // Enable scrubbing effect
            onUpdate: (self) => {
                const progress = self.progress; // Get the scrubbing progress
                const newHeight = `${progress * 100}%`;
                gsap.set('.ani-drawheight-1, .ani-drawheight-2', { height: newHeight });
            },
        });
    },
    unmounted() {
        // Destroy ScrollTrigger instances when the component is unmounted
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
    },
};
